// store.js
import { reactive } from "vue";

export const state = reactive({
    checkSoon: false,
    promoStatus: '',
    checksTotal: 0,
    checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    user: {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null
    }
});