<template>

  <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened }]">
    <div class="mobile-nav__header">
      <a href="https://jardincoffee.com/" @click="logoLinkClick('jardin')" target="_blank" class="header-logo__jardin">
        <img src="@/assets/img/ui/jardin-logo.png"
             srcset="@/assets/img/ui/jardin-logo@2x.png 2x" alt="">
      </a>

      <a href="https://greenfieldtea.co.uk/en/" @click="logoLinkClick('green')" target="_blank" class="header-logo__greenfield">
        <img src="@/assets/img/ui/greenfield-logo.png"
             srcset="@/assets/img/ui/greenfield-logo@2x.png 2x" alt="">
      </a>

      <a href="https://dostavka.magnit.ru/" @click="logoLinkClick('magnit')" target="_blank" class="header-logo__magnit">
        <img src="@/assets/img/ui/magnit-logo.png"
             srcset="@/assets/img/ui/magnit-logo@2x.png 2x" alt="">
      </a>

      <button @click="toggleMobileNav" class="button mobile-button">
        <img src="@/assets/img/icons/close.svg" alt="">
      </button>
    </div>

    <div class="mobile-nav__list">
      <a href="/#how-register" @click="handleCombinedClick('how-register')" >Как принять участие</a>
      <a href="/#prizes" @click="handleCombinedClick('prizes')">Призы</a>
      <a href="/#winners" @click="handleCombinedClick('winners')">Победители</a>
      <a href="/#faq" @click="handleCombinedClick('faq')">FAQ</a>
      <a v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">Правила акции</a>
    </div>

    <div class="mobile-nav__footer">
      <button v-if="!user" @click="openLoginPopup" class="button button-main button-main--mini">
        <span>Войти</span>
      </button>
      <template v-else>
        <button class="button button-main button-main--mini" @click="redirectToPrivatePage">
          <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
        </button>
        <button @click="logout" class="button button-logout"></button>
      </template>
    </div>
  </div>

  <header :class="{ 'is-scrolled': isHeaderScrolled }">
    <div class="container">
      <div class="header">
        <div class="header-logo">
          <a href="https://jardincoffee.com/" @click="logoLinkClick('jardin')" target="_blank" class="header-logo__jardin">
            <img src="@/assets/img/ui/jardin-logo.png"
                 srcset="@/assets/img/ui/jardin-logo@2x.png 2x" alt="">
          </a>

          <a href="https://greenfieldtea.co.uk/en/" @click="logoLinkClick('green')" target="_blank" class="header-logo__greenfield">
            <img src="@/assets/img/ui/greenfield-logo.png"
                 srcset="@/assets/img/ui/greenfield-logo@2x.png 2x" alt="">
          </a>

          <a href="https://dostavka.magnit.ru/" @click="logoLinkClick('magnit')" target="_blank" class="header-logo__magnit">
            <img src="@/assets/img/ui/magnit-logo.png"
                 srcset="@/assets/img/ui/magnit-logo@2x.png 2x" alt="">
          </a>
        </div>
        <div class="header-nav">
          <a @click="redirectToHomePage('link')" href="#">Главная</a>
          <a href="/#how-register" @click="SendMetrick('how-register')">Как принять участие</a>
          <a href="/#prizes" @click="SendMetrick('prizes')">Призы</a>
          <a href="/#winners" @click="SendMetrick('winners')">Победители</a>
          <a href="/#faq" @click="SendMetrick('faq')">FAQ</a>
        </div>
        <div class="header-user">
          <button v-if="!user" @click="openLoginPopup" class="button button-main button-main--user">
            <span>Личный кабинет</span>
          </button>
          <template v-else>
            <button class="button button-main button-main--mini" @click="redirectToPrivatePage">
              <span>{{ user.first_name }} {{ user.last_name_initial }}</span>
            </button>
            <button @click="logout" class="button button-logout"></button>
          </template>
        </div>
        <div class="mobile-menu">
          <button @click="toggleMobileNav" class="button">
            <img src="@/assets/img/icons/mobile-menu.svg" alt="">
          </button>
        </div>
      </div>
    </div>
  </header>

  <main>
    <section id="account" class="section">
      <div class="container">
        <div class="section-title">
          Личные данные
        </div>
        <form @submit.prevent="changeAccountDetails" autocomplete="off" class="edit-account-block">
          <div class="edit-account-block__form">
            <div class="personal-details-row">
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-name">Имя</label>
                    <input
                        type="text"
                        maxlength="255"
                        id="reg-name"
                        v-model="formData.first_name"
                        @input="removeSpacesFirstName"
                        name="first_name"
                        :class="{ 'is-error': errors.first_name }"
                        :disabled="isDisabled">
                    <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
                  </div>
                </div>
              </div>
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="last-name">Фамилия</label>
                    <input
                        type="text"
                        maxlength="255"
                        id="last-name"
                        v-model="formData.last_name"
                        @input="removeSpacesLastName"
                        name="last_name"
                        :class="{ 'is-error': errors.last_name }"
                        :disabled="isDisabled">
                    <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
                  </div>
                </div>
              </div>
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-email">Телефон</label>
                    <input
                        type="tel"
                        maxlength="255"
                        id="reg-email"
                        v-model="formData.phone_number"
                        name="tel"
                        :class="{ 'is-error': errors.phone_number }"
                        disabled>
                    <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
                  </div>
                </div>
              </div>
              <div class="personal-details__col">
                <div class="field-row" data-cols="1">
                  <div class="field-item">
                    <label for="reg-email">Email</label>
                    <input
                        type="text"
                        maxlength="255"
                        id="reg-email"
                        v-model="formData.email"
                        name="email"
                        disabled>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="edit-account-block__action">
            <div class="response-error" v-if="responseError">{{ responseError }}</div>

            <!-- Кнопки сохранения/отмены -->
            <button
                v-if="showSaveCancelButtons"
                type="submit"
                class="button button-main button-main--mini">
              Сохранить изменения
            </button>
            <button
                v-if="showSaveCancelButtons"
                @click="cancelChanges"
                class="button button-main button-main--outline button-main--mini">
              Отмена
            </button>

            <!-- Кнопка изменения данных -->
            <button
                v-if="!showSaveCancelButtons"
                @click="enableEditMode"
                class="button button-main button-main--outline">
              Изменить данные
            </button>

            <button
                v-if="!showSaveCancelButtons"
                class="button button-main button-main--outline" @click="openAccountChangePasswordPopup">
              <span>Изменить пароль</span>
            </button>
          </div>
        </form>
        <div class="upload-check">
          <button class="button button-main button-arrow" @click="triggerQrScan">
            <span>зарегистрировать чек</span>
          </button>
        </div>
      </div>
    </section>

    <section id="prizes" class="section">
      <div class="container">
        <div class="section-title">
          Мои призы
        </div>
        <div v-if="prizes.length > 0">
          <div class="user-prizes-list">
            <div v-for="(prize, index) in prizes" :key="index" class="user-prizes-list-item">
              <div class="user-prizes-list-item__info">
                <a v-if="prize.certificate" :href="prize.certificate" target="_blank">Скачать</a>
                <span :class="{'info-block': true, 'is-opened': isOpen[index]}">
                  <button class="info-block__open" @click="openBlock(index)"></button>
                  <span class="info-block__text">
                    <button class="info-block__close" @click="closeBlock(index)"></button>
                    {{ prize.greeting_text }}
                  </span>
                </span>
              </div>
              <div class="user-prizes-list-item__img">
                <img :src="getImageSrc(prize.cabinet_image)" />
              </div>
              <div class="user-prizes-list-item__title">
                {{ prize.title }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty">
          <div class="empty__text">
            Регистрируйте чеки и выигрывайте призы
          </div>
        </div>
        <div class="upload-check">
          <button class="button button-main button-arrow" @click="triggerQrScan">
            <span>зарегистрировать чек</span>
          </button>
        </div>
      </div>
    </section>

    <section id="uploaded-checks" class="section">
      <div class="container">
        <div class="section-title">
          Зарегистрированные чеки
        </div>
        <div class="empty" v-if="state.checks.results.length === 0">
          <div class="empty__text">
            Вы не зарегистрировали ни одного чека
          </div>
        </div>
        <div class="users-check-list" v-else>
          <div v-if="hasSpecificType" class="user-address">
            <span v-if="!isAddressFilled">
              Для получения приза уточните информацию и адрес доставки
            </span>
            <span v-else>
              <p>Адрес успешно добавлен</p>
              <p><small>Для изменения данных, обратитесь с запросом в <a @click="triggerQuestionForm">службу поддержки</a></small></p>
            </span>

            <button
                v-if="!isAddressFilled"
                @click="openAddressPopup"
                class="button button-main button-main--mini button-arrow">Добавить</button>

            <span v-else class="address-icon">
              <img src="@/assets/img/account/address-sent.svg" alt="">
            </span>
          </div>
          <div v-if="state.checkSoon" class="check-item check-item--soon">
            <div class="check-item__id">
              XXXXX
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z" stroke="#D1AF87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="check-item__date">
              <span>XX.XX.XXXX</span>
              <span>XX:XX</span>
            </div>
            <div class="check-item__img">
              <img src="@/assets/img/temp/check.jpg" alt="">
            </div>
            <div class="check-item__status">
              XXXXXX
            </div>
          </div>
          <div v-for="(check, cIndex) in state.checks.results" :key="check.id" :class="['check-item', getStatusClass(check.status)]">
            <div class="check-item__id">
              {{ check.number }}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="copyIdToClipboard(check.number)">
                <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z" stroke="#D1AF87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="check-item__date">
              <span>{{ formatDate(check.upload_date) }}</span>
              <span>{{ formatTime(check.upload_date) }}</span>
            </div>
            <div v-if="check.images" class="check-item__img">
              <a v-for="(image, index) in check.images" :key="index" :href="getImageSrc(image)" :data-fancybox="'user-check-' + cIndex">
                <img :src="getImageSrc(image)" />
                <button class="button button-main button-main--mini">
                  <span>Открыть</span>
                </button>
              </a>
            </div>
            <div class="check-item__status">
              {{ getStatusText(check.status) }}
              <span v-if="check.reject_reason" :class="{'info-block': true, 'is-opened': isOpen[prizes.length + cIndex]}">
                  <button class="info-block__open" @click="openBlock(prizes.length + cIndex)"></button>
                  <span class="info-block__text">
                    <button class="info-block__close" @click="closeBlock(prizes.length + cIndex)"></button>
                    {{ check.reject_reason }}
                  </span>
                </span>
            </div>
          </div>
          <div class="load-more-checks">
            <button v-if="state.checks.next"  class="button button-main" @click="loadMoreChecks">
              <span>Показать ещё</span>
            </button>
          </div>
        </div>
        <div class="upload-check">
          <button class="button button-main button-arrow" @click="triggerQrScan">
            <span>зарегистрировать чек</span>
          </button>
        </div>
      </div>
    </section>
  </main>

  <!-- Popup для изменения пароля -->
  <div v-if="showAccountChangePasswordPopup" class="popup">
    <div class="popup__overlay" @click="closeAccountChangePasswordPopup"></div>
    <div class="popup__window">
      <button @click="closeAccountChangePasswordPopup" class="popup__close"></button>
      <div class="popup__title">Изменить пароль</div>
      <div class="popup__content">
        <form @submit.prevent="changeAccountPassword" autocomplete="off">
          <!-- Поле пароля -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="password">Пароль</label>
              <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password"
                  v-model="password"
                  name="password"
              />
              <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
              <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
            </div>
          </div>
          <!-- Поле подтверждения пароля -->
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="password">Подтверждение пароля</label>
              <input
                  :type="passwordVisible ? 'text' : 'password'"
                  maxlength="255"
                  id="password_check"
                  v-model="password_check"
                  name="password"
              />
              <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
              <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password_check }}<br></span>
            </div>
          </div>
          <!-- Кнопка для отправки формы регистрации -->
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-main button-main--big button-main--full"
                      :disabled="!isFormValid">
                <span>Изменить пароль</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showAccountChangePasswordSuccess" class="popup">
    <div class="popup__overlay" @click="closeAccountChangePasswordSuccess"></div>
    <div class="popup__window">
      <button @click="closeAccountChangePasswordSuccess" class="popup__close"></button>
      <div class="popup__content">
        <div class="success-message">
          Ваш пароль успешно обновлён
        </div>
      </div>
    </div>
  </div>

  <!-- Popup для добавление адреса -->
  <div v-if="showUserAddressFormPopup" class="popup">
    <div class="popup__overlay" @click="closeUserAddressFormPopup"></div>
    <div class="popup__window">
      <button @click="closeUserAddressFormPopup" class="popup__close"></button>
      <div class="popup__title">Данные для доставки подарка</div>
      <div class="popup__content">
        <form @submit.prevent="sendUserAddressForm" autocomplete="off">
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-fio">ФИО</label>
              <input
                  type="text"
                  maxlength="255"
                  id="address-fio"
                  v-model="addressForm.fio"
                  @blur="clearError('fio')"
                  :class="{ 'is-error': errors.fio }"
                  name="address-fio"
              />
              <span v-if="errors.fio" class="error-message">{{ errors.fio }}<br></span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-phone">Номер телефона</label>
              <input type="text" maxlength="18" id="address-phone" v-model="addressForm.phone_number" name="phone_number"
                     autocomplete="off"
                     @blur="clearError('phone_number')"
                     :class="{ 'is-error': errors.phone_number }"
                     v-mask>
              <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-email">Email</label>
              <input
                  type="text"
                  maxlength="255"
                  id="address-email"
                  @blur="clearError('email')"
                  v-model="addressForm.email"
                  :class="{ 'is-error': errors.email }"
                  name="address-email"
              />
              <span v-if="errors.email" class="error-message">{{ errors.email }}<br></span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-city">Город</label>
              <input
                  type="text"
                  maxlength="255"
                  id="address-city"
                  @blur="clearError('city')"
                  v-model="addressForm.city"
                  :class="{ 'is-error': errors.city }"
                  name="address-city"
              />
              <span v-if="errors.city" class="error-message">{{ errors.city }}<br></span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-street">Улица</label>
              <input
                  type="text"
                  maxlength="255"
                  id="address-street"
                  @blur="clearError('street')"
                  v-model="addressForm.street"
                  :class="{ 'is-error': errors.street }"
                  name="address-street"
              />
              <span v-if="errors.street" class="error-message">{{ errors.street }}<br></span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-house">Дом</label>
              <input
                  type="text"
                  maxlength="255"
                  id="address-house"
                  @blur="clearError('house')"
                  v-model="addressForm.house"
                  :class="{ 'is-error': errors.house }"
                  name="address-house"
              />
              <span v-if="errors.house" class="error-message">{{ errors.house }}<br></span>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="address-zip_code">Почтовый индекс</label>
              <input
                  type="text"
                  maxlength="6"
                  id="address-house"
                  @input="filterZipCode"
                  @blur="clearError('zip_code')"
                  v-model="addressForm.zip_code"
                  :class="{ 'is-error': errors.zip_code }"
                  name="address-zip_code"
              />
              <span v-if="errors.zip_code" class="error-message">{{ errors.zip_code }}<br></span>
            </div>
          </div>
          <div class="field-row field-row-action" data-cols="1">
            <div class="field-item">
              <div class="response-error" v-if="responseError">{{ responseError }}</div>
              <button type="submit" class="button button-main button-main--big button-main--full">
                <span>Отправить</span>
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div v-if="showUserAddressFormPopupSuccess" class="popup">
    <div class="popup__overlay" @click="closeUserAddressFormPopupSuccess"></div>
    <div class="popup__window">
      <button @click="closeUserAddressFormPopupSuccess" class="popup__close"></button>
      <div class="popup__title">Данные для доставки подарка</div>
      <div class="popup__content">
        <p>Данные для доставки успешно отправлены</p>
        <p><button class="button button-main button-main--big button-main--full" @click="closeUserAddressFormPopupSuccess">Закрыть</button></p>
      </div>
    </div>
  </div>

  <!-- Popup статусов акции -->
  <div v-if="showPromoNotStarted" class="popup">
    <div class="popup__overlay" @click="closeShowPromoNotStarted"></div>
    <div class="popup__window">
      <button @click="closeShowPromoNotStarted" class="popup__close"></button>
      <div class="popup__content">
        Акция ещё не началась
      </div>
    </div>
  </div>
  <div v-if="showPromoEnd" class="popup">
    <div class="popup__overlay" @click="closeShowPromoEnd"></div>
    <div class="popup__window">
      <button @click="closeShowPromoEnd" class="popup__close"></button>
      <div class="popup__content">
        Акция завершена
      </div>
    </div>
  </div>

</template>

<script>
/* global qrWidget */
import {
  getMainSettings,
  getToken,
  getUserInfo,
  changeProfile,
  setAuthHeader,
  getWinningPrizes,
  getUserChecks,
  changePassword,
  removeToken,
  sendUserAddress
} from "@/api";
import { state } from "@/store";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import metrikaMixin from "@/mixins/metrikaMixin";

export default {
  name: 'PrivatePage',
  mixins: [metrikaMixin],
  setup() {
    return {
      state, // Доступ к глобальному состоянию
    };
  },
  data() {
    return {
      checkInterval: null,
      checksTotal: 0,
      hasSpecificType: false,
      addressForm: {
        fio: '',
        phone_number: '',
        email: '',
        city: '',
        street: '',
        house: '',
        zip_code: '',
      },
      isAddressFilled: false,
      isHeaderScrolled: false,
      scrollThreshold: 0,
      isMobileNavOpened: false,
      formData: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: ''
      },
      user: null,
      password: '',
      password_check: '',
      loginFormErrors: {},
      passwordVisible: false,
      rootUrl: window.location.origin,
      prizes: [],
      isOpen: [],
      errors: {},
      showEndPopupSuccess: false,
      showAccountChangePasswordPopup: false,
      showUserAddressFormPopup: false,
      showUserAddressFormPopupSuccess: false,
      showAccountDetailsPopup: false,
      showAccountDetailsSuccess: false,
      showAccountChangePasswordSuccess: false,
      showPromoNotStarted: false,
      showPromoEnd: false,
      mainSettings: {},
      activeAccountSection: 0,
      scrolledAccountSection: -1,
      accountUser: {
        first_name: this.user && this.user.first_name ? this.user.first_name : '',
        last_name: this.last_name && this.user.last_name ? this.user.last_name : '',
        email: this.email && this.user.email ? this.user.email : '',
        phone_number: this.phone_number && this.user.phone_number ? this.user.phone_number : ''
      },
      responseError: '',
      isDisabled: true,
      showSaveCancelButtons: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      accountDetailsForm: {
        first_name: '',
        last_name: '',
        email: '',
      },
      checks: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      checkOffset: 0,
      checkLimit: 18,
      statusTextMap: {
        0: 'На проверке',
        1: 'На проверке',
        3: 'Одобрен',
        4: 'Отклонен',
        5: 'Выиграл',
      },
      accountSections: [
        { name: 'Личные данные', component: 'PersonalData', scrolled: false },
        { name: 'Выигрынные призы', component: 'WinningPrizes', scrolled: false},
        { name: 'Загруженные чеки', component: 'QuizSection', scrolled: false },
      ],
    }
  },
  mounted() {
    Fancybox.bind("[data-fancybox]", {
    });
    this.initializeOpenState();
    this.fetchWinningPrizes();
    this.fetchUsersCheck();
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.accountUser = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email,
          };
          state.user = {
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            phone_number: userInfo.phone_number,
            email: userInfo.email
          }
          this.formData = { ...this.accountUser };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
          this.userId = userInfo.id;
          this.userUuid = userInfo.uuid;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.isAddressFilled = Boolean(userInfo.address_is_filled);
          this.initQrWidget();
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    isFormValid() {
      return (
          this.password.length >= 6 &&
          this.password_check.length >= 6 &&
          this.password === this.password_check
      );
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    executeAdriverEvent(page) {
      const sidMapping = {
        faq: 'click_faq',
        prizes: 'click_prizes',
        winners: 'click_winners',
        register_user_closed: 'abandoned_user_registration_close_form',
        check_upload_closed: 'abandoned_check_registration_close_form',
        click_greenfield: 'click_greenfield',
        click_jardin: 'click_jardin',
        click_magnit: 'click_magnit',
        click_home: 'click_main',
        upload_check_open: 'click_register_check',
        click_how_use: 'click_how_participate',
        click_account: 'click_lk',
        register_user_success: 'successful_user_registration',
        timer_15: 'timer_15'
      };

      if (!sidMapping[page]) return;

      const sid = 229236; // ваш статический sid
      const bt = 62; // ваш статический bt
      const sz = sidMapping[page];

      // Вызов Adriver-кода
      (function (e, n) {
        function o(e, n, o) {
          n = n || "&";
          o = o || "=";
          var d = [];
          for (var r in e)
            if (Object.prototype.hasOwnProperty.call(e, r)) {
              d.push(r + o + encodeURIComponent(e[r]));
            }
          return d.join(n);
        }
        function d(e, n) {
          var o = e.cookie.match("(^|;) ?" + n + "=([^;]*)(;|$)");
          return o ? decodeURIComponent(o[2]) : null;
        }
        var r,
            t,
            i,
            c,
            u;
        (r = e),
            (t = n),
            (i = document.domain),
            (c = { tail256: document.referrer || "unknown" }),
        void 0 !==
        (u = (function (e) {
          var n = {};
          if (e) {
            var o = e.split("&");
            for (var d in o)
              if (Object.prototype.hasOwnProperty.call(o, d)) {
                var r = o[d].split("=");
                void 0 !== r[0] &&
                void 0 !== r[1] &&
                (n[r[0]] = decodeURIComponent(r[1]));
              }
          }
          return n;
        })(window.location.search.substring(1))).adrclid && (r.fsid = u.adrclid),
        null !== d(document, "adrcid") && (r.cid = d(document, "adrcid")),
        t &&
        t.id &&
        null !== d(document, t.id) &&
        (r.suid = i + "_" + encodeURIComponent(d(document, t.id))),
            t && t.gid1
                ? (r.gid1 = t.gid1)
                : null !== d(document, "_ga") &&
                (r.gid1 = encodeURIComponent(d(document, "_ga"))),
            t && t.yid1
                ? (r.yid1 = t.yid1)
                : null !== d(document, "_ym_uid") &&
                (r.yid1 = encodeURIComponent(d(document, "_ym_uid"))),
            (r.loc = encodeURIComponent(window.location.href)),
        r.custom && (r.custom = o(r.custom, ";")),
            (function (e, n) {
              !(function (e) {
                if (
                    ((e = e.split("![rnd]").join(~~(1e6 * Math.random()))),
                    document.createElement && document.body)
                ) {
                  var n = document.createElement("img");
                  (n.style.position = "absolute"),
                      (n.style.display = "none"),
                      (n.style.width = n.style.height = "0px"),
                      n.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (n.src = e),
                      document.body.appendChild(n);
                } else {
                  var o = new Image();
                  o.setAttribute("referrerpolicy", "no-referrer-when-downgrade"),
                      (o.src = e);
                }
              })(
                  "https://ad.adriver.ru/cgi-bin/rle.cgi?" +
                  e +
                  "&rnd=![rnd]" +
                  (n ? "&" + n : "")
              );
            })(o(r), o(c));
      })({ sid: sid, bt: bt, sz: sz }, { id: "", gid1: "", yid1: "" });
    },
    executeDSPCounterEvent(siteArea) {
      const sid = 229303; // ваш статический sid
      const siteAreaMapping = {
        '30_sec': 'TJCuegy2',
        'login_success': 'qh1jImpT',
        'check_upload_success': 'hAf9oUO0',
        'buttons_click': 'bG46a4ie',
        'faq_click': 'USZY4vWn',
      };

      const siteAreaId = siteAreaMapping[siteArea] || 'USZY4vWn';


      // Проверка и отложенный вызов если DSPCounter еще не загружен
      const checkDSPCounter = setInterval(() => {
        if (window.DSPCounter) {
          clearInterval(checkDSPCounter);
          window.DSPCounter('send', {
            'sid': sid,
            'site_area': siteAreaId,
            'user_id': '',
            'lead_id': '',
            'order_sum': ''
          });
        }
      }, 100); // проверяем каждые 100ms
    },
    SendMetrick(page) {
      if (page == 'how-register') {
        this.$sendMetrik('reachGoal', 'click_how_use');
        this.executeAdriverEvent('click_how_use');
      } else if (page == 'faq') {
        this.$sendMetrik('reachGoal', 'click_faq');
        this.executeAdriverEvent('faq');
      } else if (page == 'prizes') {
        this.$sendMetrik('reachGoal', 'click_prize');
        this.executeAdriverEvent('prizes');
      } else if (page == 'winners') {
        this.$sendMetrik('reachGoal', 'click_winners');
        this.executeAdriverEvent('winners');
      }
    },
    startCheckingForNewChecks() {
      // Если уже идет проверка, не запускаем ещё раз
      if (this.checkInterval) return;

      this.checkInterval = setInterval(async () => {
        try {
          const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

          if (checksData.count && checksData.count !== state.checksTotal) {
            // Обновляем данные, если количество чеков изменилось
            state.checksTotal = checksData.count;
            state.checks.results = checksData.results;

            // Останавливаем таймер
            this.stopCheckingForNewChecks();

            // Очищаем флаг ожидания
            state.checkSoon = false;
          }
        } catch (error) {
          console.error('Error checking for new checks:', error);
        }
      }, 5000); // Проверяем каждые 5 секунд
    },
    stopCheckingForNewChecks() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
    filterZipCode() {
      this.addressForm.zip_code = this.addressForm.zip_code.replace(/\D/g, '');
    },
    async sendUserAddressForm() {
      try {
        const { fio, phone_number, email, city, street, house, zip_code } = this.addressForm;

        // Форматирование номера телефона (пример, если необходимо)
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Очистка ошибок перед проверкой
        this.errors = {};

        // Валидация каждого поля
        if (fio.length < 3) {
          this.errors.fio = 'ФИО должно быть не менее 3 символов.';
        }
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный номер телефона.';
        }

        // Пример проверки email на корректность
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Проверка на пустой email и корректный формат
        if (email.trim() === '') {
          this.errors.email = 'Поле не может быть пустым.';
        } else if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email.';
        }

        // Проверка почтового индекса на длину
        if (zip_code.length !== 6) {
          this.errors.zip_code = 'Почтовый индекс должен содержать 6 цифр.';
        }

        // Проверка обязательных полей (например, город, улица и дом)
        if (!city) {
          this.errors.city = 'Город обязательно';
        }
        if (!street) {
          this.errors.street = 'Улица обязательно';
        }
        if (!house) {
          this.errors.house = 'Дом обязателен';
        }

        // Если есть ошибки, не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка данных с уже отформатированным номером
        const payload = {
          fio,
          phone_number: formattedPhoneNumber,
          email,
          city,
          street,
          house,
          zip_code
        };

        // Здесь можно вызвать функцию для отправки данных
        await sendUserAddress(payload);

        // Если все прошло успешно
        this.isAddressFilled = Boolean(true);
        this.showUserAddressFormPopupSuccess = true;
        this.showUserAddressFormPopup = false;
        // Очистить форму или выполнить другие действия после успешной отправки
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    clearError(field) {
      this.errors[field] = '';
    },
    copyIdToClipboard(id) {
      navigator.clipboard.writeText(id).then(() => {
        console.log("ID скопирован в буфер обмена!");
      })
    },
    enableEditMode() {
      this.isDisabled = false; // Разблокировать поля
      this.showSaveCancelButtons = true; // Показать кнопки "Сохранить" и "Отменить"
      this.formData = { ...this.accountUser };
    },
    cancelChanges() {
      this.isDisabled = true;
      this.showSaveCancelButtons = false;
      this.formData = { ...this.accountUser };
    },

    initQrWidget() {
      if (!document.getElementById('apm-scan-qr')) {
        qrWidget.init('apm-widget', {
          api: 'https://api.apmcheck.ru',
          apiKey: '3f9cbff2-2d9f-41b5-ab14-bf89252ac7d1',
          userUuid: this.userUuid,
          callbacks: {
            onReceiptSentSuccess: () => {
              this.$sendMetrik('reachGoal', 'check_upload_success');
              this.executeAdriverEvent('check_upload_success');
              this.executeDSPCounterEvent('check_upload_success');
              state.checkSoon = true;
              this.startCheckingForNewChecks();
            }
          }
        });
      }
    },
    redirectToPrivatePage() {
      this.$sendMetrik('reachGoal', 'click_account');
      this.executeAdriverEvent('click_account');
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    handleRulesButtonClick() {
      // this.$sendMetrik('reachGoal', 'Rules_Click');
    },
    async logout() {
      state.checkSoon = false;
      state.promoStatus = '';
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
      state.user = {
        first_name: null,
        last_name: null,
        phone_number: null,
        email: null
      };
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = ''; // Очистка содержимого виджета
      }

      this.toggleMobileNav(); // Логика для переключения навигации
      removeToken(); // Удаление токена (например, из localStorage)
      this.user = null; // Обновление состояния пользователя
      this.questionForm = { // Очистка формы
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };

      // Ожидание завершения обновления DOM
      await this.$nextTick();

      // Выполняем перенаправление после обновления DOM
      window.location.href = '/';
    },
    openLoginPopup() {
      if (state.promoStatus == 'start' || state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showLoginPopup = true;
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.responseError = '';
        this.showPromoNotStarted = true;
      }
    },
    handleCombinedClick(target) {
      this.toggleMobileNav();
      this.SendMetrick(target);
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    redirectToHomePage(page) {
      if (page == 'link') {
        this.$sendMetrik('reachGoal', 'click_home');
        this.executeAdriverEvent('click_home');
      }
      this.$router.push('/');
    },
    logoLinkClick(page) {
      if (page == 'magnit') {
        this.$sendMetrik('reachGoal', 'click_magnit');
      } else if (page == 'jardin') {
        this.$sendMetrik('reachGoal', 'click_jardin');
        this.executeAdriverEvent('click_jardin');
      } else if (page == 'green') {
        this.$sendMetrik('reachGoal', 'click_greenfield');
        this.executeAdriverEvent('click_greenfield');
      }
    },
    addBodyClass() {
      document.body.classList.add('is-popup-opened');
    },
    removeBodyClass() {
      document.body.classList.remove('is-popup-opened');
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    loadMoreChecks() {
      if (state.checks.next) {
        this.checkOffset += 18;
        this.fetchUsersCheck();
      }
    },
    initializeOpenState() {
      this.isOpen = [...this.prizes, ...state.checks.results].map(() => false);
      state.checksTotal = 0;
      state.checks = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    },
    openBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = true; // Установка состояния для открытия блока
      });
    },
    closeBlock(index) {
      this.$nextTick(() => {
        this.isOpen[index] = false; // Установка состояния для закрытия блока
      });
    },
    getImageSrc(imageUrl) {
      // You can add any logic here to manipulate or format the image URL if needed
      return imageUrl;
    },
    getStatusText(status) {
      return this.statusTextMap[status] || 'Unknown';
    },
    getStatusClass(status) {
      return {
        'status-loaded': status === 0,
        'status-pending': status === 1,
        'status-approved': status === 3,
        'status-rejected': status === 4,
        'status-won': status === 5,
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    triggerQrScan() {
      this.executeDSPCounterEvent('buttons_click');
      if (state.promoStatus == 'start') {
        const qrElement = document.getElementById('apm-scan-qr');
        if (qrElement) {
          this.$sendMetrik('reachGoal', 'upload_check_open');
          this.executeAdriverEvent('upload_check_open');
          qrElement.click();
        }
      } else if (state.promoStatus == 'not-started') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoNotStarted = true;
      } else if (state.promoStatus == 'end') {
        this.addBodyClass();
        this.isMobileNavOpened = false;
        this.showPromoEnd = true;
      }
    },
    triggerQuestionForm() {
      const qrElement = document.getElementById('triggerQuestionForm');
      if (qrElement) {
        qrElement.click();
      }
    },
    async fetchWinningPrizes() {
      try {
        const prizes = await getWinningPrizes();
        this.prizes = prizes.results;
        this.hasSpecificType = this.prizes.some(prize => [3, 4].includes(prize.id));
      } catch (error) {
        console.error('Error fetching winning prizes:', error);
      }
    },
    async fetchUsersCheck() {
      try {
        const checksData = await getUserChecks(this.checkOffset, this.checkLimit);

        if (checksData.count) {
          state.checksTotal = checksData.count;
        }

        // Добавляем новые чеки к существующим
        state.checks.results.push(...checksData.results);

        // Обновляем значение next
        state.checks.next = checksData.next;

      } catch (error) {
        console.error('Error fetching checks:', error);
      }
    },
    removeSpacesFirstName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.first_name = input;
    },
    removeSpacesLastName() {
      const input = event.target.value.replace(/\s+/g, '');
      this.accountUser.last_name = input;
    },
    openAccountChangePasswordPopup() {
      this.addBodyClass();
      this.showAccountChangePasswordPopup = true;
      this.responseError = '';
    },
    closeAccountChangePasswordPopup() {
      this.removeBodyClass();
      this.showAccountChangePasswordPopup = false;
    },
    openAddressPopup() {
      this.addBodyClass();
      this.showUserAddressFormPopup = true;
      this.responseError = '';
    },
    closeUserAddressFormPopup() {
      this.removeBodyClass();
      this.showUserAddressFormPopup = false;
      this.responseError = '';
    },
    closeUserAddressFormPopupSuccess() {
      this.removeBodyClass();
      this.showUserAddressFormPopupSuccess = false;
      this.responseError = '';
    },
    openAccountDetailsPopupSuccess() {
      this.addBodyClass();
      this.showAccountDetailsSuccess = true;
      this.responseError = '';
    },
    closeEndPopupSuccess() {
      this.removeBodyClass();
      this.showEndPopupSuccess = false;
      this.responseError = '';
    },
    openAccountChangePasswordPopupSuccess() {
      this.addBodyClass();
      this.showAccountChangePasswordSuccess = true;
      this.responseError = '';
    },
    closeAccountDetailsSuccess() {
      this.removeBodyClass();
      this.showAccountDetailsSuccess = false;
    },
    closeAccountChangePasswordSuccess() {
      this.removeBodyClass();
      this.showAccountChangePasswordSuccess = false;
    },
    closeShowPromoNotStarted() {
      this.removeBodyClass();
      this.showPromoNotStarted = false;
    },
    closeShowPromoEnd() {
      this.removeBodyClass();
      this.showPromoEnd = false;
    },
    async changeAccountPassword() {
      try {
        const { password, password_check } = this;

        // Проверка полей
        this.errors = {};
        if (password.length < 6) {
          this.errors.password = 'Пароль должен быть не менее 6 символов';
        }
        if (password_check.length < 6) {
          this.errors.password_check = 'Подтверждение пароля должно быть не менее 6 символов';
        }
        if (password !== password_check) {
          this.errors.password_check = 'Пароли не совпадают';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение пароля
        await changePassword(password);

        // Успешное завершение действий, например, закрытие модального окна или уведомление
        this.closeAccountChangePasswordPopup();
        this.openAccountChangePasswordPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async changeAccountDetails() {
      try {
        const { first_name, last_name, email, phone_number } = this.formData;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }

        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на изменение данных (например, через API)
        await changeProfile(first_name, last_name, email, phone_number);

        // Если запрос успешен, обновляем accountUser
        this.accountUser = { ...this.formData };

        this.isDisabled = true;
        this.showSaveCancelButtons = false;
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
  },
  beforeUnmount() {
    // Очищаем таймер при разрушении компонента
    this.stopCheckingForNewChecks();
  },
  async created() {
    try {
      const [mainSettingsData] = await Promise.all([
        getMainSettings()
      ]);
      this.mainSettings = mainSettingsData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
};
</script>